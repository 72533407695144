import React from 'react';
import HamburgerMenu from '../../components/ui/HamburgerMenu';
import './kentPopExchange.css';

function KentPopExchange() {
  return (
    <>
      <HamburgerMenu />
      <div className='masterContainerKps'>
        <h1>Kent Pop Exchange</h1>
        <div className='blurbKps'>
          <p>
            A home to share music from Kent's emerging artists
          </p>
        </div>
        <div className='about'>
          <p> Want to play some shows with Whereelse or Awkwardness happening? If you're
            a band based in Kent and want to play some shows with us, fill out the form below.
          </p>
        </div>
        <form className='form'>
          <h3>Name</h3>
          <input type='text' placeholder='Name' />
          <h3>Email</h3>
          <input type='text' placeholder='Email' />
          <h3>Band Name</h3>
          <input type='text' placeholder='Band Name' />
          <h3>Bandcamp Link</h3>
          <input type='text' placeholder='Bandcamp Link' />
          <div className='submitContainer'>
            <button type='submit'>Submit</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default KentPopExchange;
