import React, { useState, useRef, useEffect } from 'react';

function FadeInTextSection(props) {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      });
    });
  
    if (domRef.current) {  // Ensure domRef.current is valid before observing
      observer.observe(domRef.current);
    }
  
    return () => {
      if (domRef.current) {  // Ensure domRef.current is valid before unobserving
        observer.unobserve(domRef.current);
      }
    };
  }, []);
  return (
    <div
      className={`fade-in-text-section ${isVisible ? 'visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

export default FadeInTextSection;
