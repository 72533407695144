import React from "react";


const ShopPreview = () => {
  return (
    <div className="shop-preview">
      <h1> Merch </h1>
      <div>
      </div>
    </div>
  )
}

export default ShopPreview; 