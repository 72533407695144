import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import Splash from './pages/Home/splash';
import Blurb from './pages/Home/Blurb';
import Temp from './tempComp';
import DiceEventList from './pages/Events/Dice';
import Banner from './pages/Home/Banner';
import FadeInBackground from './components/ui/FadeInBackground';
import KentPopExchangePreview from './pages/Home/KentPopExchangePreview';
import ShopPreview from './pages/Shop/Shop';
import FadeInTextSection from './components/ui/FadeInTextSection';
import DicePreview from './pages/Home/DiceWidgetPreview';
import HamburgerMenu from './components/ui/HamburgerMenu';
import Home from './pages/Home/Home';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Events from './pages/Events/Events';
import KentPopExchange from './pages/KentPopExchange/kentPopExchange';

// ... other imports as needed

function App() {
  
  const targets = [
    { className: 'navSection', color: '#A9EB7D' },
    { className: 'videoSection', color: '#A9EB7D' },
    { className: 'content', color: '#A9EB7D' },
    // { className: 'wid', color: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)' },
    { className: 'kentPopExchange', color: '#FD69B8'}
    // Add other target class names and colors as needed
  ];

  useEffect(() => {

    document.title = "WhereElse?"

    return () => {
      document.title = "WhereElse?"
    };
  }, []);

  

  return (
      <div className="App">
        <Routes>  
        <Route path='/home' element={<Home />} />
        <Route path='/Events' element={<Events />} />
        <Route path='/Shop' element={<ShopPreview />} />
        <Route path='/KentPopExchange' element={<KentPopExchange />} />
        <Route path='/' element={<Navigate to="/home" />} />
        <Route />
      </Routes>
        {/* <FadeInBackground targets={targets}>
          <div className='masterContainer'>
            <div className='navSection'>
              <HamburgerMenu />
            </div>
            <div className='videoSection'>
              <Splash />
            </div>
            <div className="content">
              <FadeInTextSection>
                <Blurb />
                <DicePreview />
              </FadeInTextSection>
            </div>
            <FadeInTextSection>
              <div className="wid">
              </div>
            </FadeInTextSection>
            <div className='kpsContainer'>
              <KentPopExchange />
            </div>
          </div>
        </FadeInBackground> */}
      </div>

  );
}

export default App;
