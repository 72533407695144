import React, { useEffect, useRef } from 'react';

function ZohoForm() {
  const formDivRef = useRef(null);

  useEffect(() => {
    try {
      // Check if iframe already exists
      const existingIframe = formDivRef.current.getElementsByTagName("iframe")[0];
      if (existingIframe) return; // If iframe already exists, exit the effect
      
      var f = document.createElement("iframe");
      f.src = 'https://forms.zohopublic.eu/samuelawalton/form/ContactUs/formperma/MFgVCja2n9tdupzmVXHCCngCA_76T224qhhcQnF8KpE?zf_rszfm=1';
      f.style.border = "none";
      f.style.height = "800px";
      f.style.width = "100%";
      f.style.transition = "all 0.5s ease";
      
      formDivRef.current.appendChild(f);

      const messageEventListener = function(event) {
        var evntData = event.data;
        if (evntData && typeof evntData === "string") {
          var zf_ifrm_data = evntData.split("|");
          if (zf_ifrm_data.length === 2) {
            var zf_perma = zf_ifrm_data[0];
            var zf_ifrm_ht_nw = (parseInt(zf_ifrm_data[1], 10) + 15) + "px";
            var iframe = formDivRef.current.getElementsByTagName("iframe")[0];
            if (iframe.src.indexOf('formperma') > 0 && iframe.src.indexOf(zf_perma) > 0) {
              var prevIframeHeight = iframe.style.height;
              if (prevIframeHeight !== zf_ifrm_ht_nw) {
                iframe.style.height = zf_ifrm_ht_nw;
              }
            }
          }
        }
      };

      window.addEventListener('message', messageEventListener, false);

      return () => {
        // Cleanup: remove event listener and iframe
        window.removeEventListener('message', messageEventListener);
        if (formDivRef.current) {
          while (formDivRef.current.firstChild) {
            formDivRef.current.removeChild(formDivRef.current.firstChild);
          }
        }
      };

    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <div ref={formDivRef} id="zf_div_MFgVCja2n9tdupzmVXHCCngCA_76T224qhhcQnF8KpE"></div>
  );
}

export default ZohoForm;
