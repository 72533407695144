import React, { useEffect, useRef } from 'react';

const YouTubeVideo = ({ videoId }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    // Ensure the API is ready
    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('ytplayer', {
        videoId: videoId,
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        },
        playerVars: {
          'autoplay': 1,
          'controls': 1,
          'showinfo': 0,
          'modestbranding': 1,
          'loop': 1,
          'playlist': videoId
        }
      });
    };

    const onPlayerReady = (event) => {
      event.target.playVideo();
    };

    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.ENDED) {
        event.target.playVideo();
      }
    };

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [videoId]);

  return (
    <div className="mainVid">
      <div className="videoWrapper">
        <div id="ytplayer"></div>
      </div>
    </div>
  );
};

export default YouTubeVideo;