import React from "react";
import '../../index.css'; // Make sure to import your CSS file

const MerchPreview = () => {
  return (
  <>
    <div className="merchSection">
        <h1>Merchandise</h1>
        {/* <div class="wavy">
          <div class="line">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
          <div class="line">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
          <div class="line">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
          <div class="line">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</div>
        </div> */}

        <div className="dash1">
        </div>
        <div className="dash2">
        </div>
        <div className="dash3">
        </div>
        <div className="dash4">
        </div>
        <div className="dash5">
        </div>
        <div className="dash6">
        </div>
        <div class="image-container">
          <a href="https://whereelse.bigcartel.com/products" target="_blank">
            <div class="overlay">
              <p class="overlay-text">BUY ME!</p>
            </div>
          </a>
        </div>
        {/* <img src="https://assets.bigcartel.com/product_images/379844181/OrangeTshirt_PackShot.jpg?auto=format&fit=max&w=1400" alt="Merchandise"/> */}
    </div>

  </>
  )
}

export default MerchPreview;
