import React, { useEffect } from "react";

const DiceEventList = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widgets.dice.fm/dice-event-list-widget.js";
    script.async = true;

    script.onload = () => {
      try {
        window.DiceEventListWidget.create({
          information: "minimal",
          eventTitle: "event",
          showImages: true,
          showAudio: true,
          showNewBadge: false,
          hidePostponed: false,
          hideCancelled: false,
          layout: "gallery",
          roundButtons: true,
          theme: "dark",
          fontFamily: "inherit",
          partnerId: "bfdbd984",
          apiKey: "KJ5BcUn3hJ7hUwivgTnM93tFGK0asvkq8UPi2Fw8",
          version: 2,
          showPrice: false,
          __p: false,
          __pc: {},
          highlightColour: "white",
          promoters: ["Night Harvest", "Art's Cool"],
          numberOfEvents: "24",
          eventListLink: "https://dice.fm/venue/whereelse-r76q?lng=en"
        });
        console.log("Dice Event List Widget created successfully.");
      } catch (error) {
        console.error("Error creating Dice Event List Widget:", error);
      }
    };

    script.onerror = () => {
      console.error("Error loading the Dice Event List Widget script.");
    };

    // Attach the script to the body
    document.body.appendChild(script);

    // Cleanup function
    return () => {
      // Remove the script from the document to avoid memory leaks
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="dice-event-list-widget">
      {/* Widget will be injected here */}
    </div>
  );
};

export default DiceEventList;
