import React, { useState } from 'react';
import './HamburgerMenu.css';
import { Link } from 'react-router-dom';
import Events from '../../pages/Events/Events';
import KentPopExchange from '../../pages/KentPopExchange/kentPopExchange';
import where_else_logo from '../../assets/WhereElse_Logo_3.png';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div 
        className={`backdrop ${isOpen ? 'show-backdrop' : ''}`} 
        onClick={toggleMenu}
      ></div>

      <div className="menu-container">
        <button className="hamburger" onClick={toggleMenu}>
          &#9776;
        </button>
        {isOpen && (
          <div className="menu">
            <a href="/Home">Home</a>
            <a href="https://dice.fm/venue/whereelse-r76q" target="_blank'">Events</a>
            <a href="https://whereelse.bigcartel.com/products" target="_blank">Shop</a>
            <img className="logoHamburger" src={where_else_logo}/>
          </div>
        )}
      </div>
    </>
  );
};

export default HamburgerMenu;
