import React, { useEffect, useState } from "react";
import logoImg from '../assets/My project.png'

const Navbar = () => {
  const [isNavbarVisible, setNavbarVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setNavbarVisible(!scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${isNavbarVisible ? "" : "hidden"}`}>
      {/* <img className="logo" src={logoImg}/> */}
      {/* Navigation links */}
      <div className="links">
        <a href="/home">Home</a>
        <a href="https://dice.fm/venue/whereelse-r76q" target="_blank">Events</a>
        <a href="https://whereelse.bigcartel.com/products" target="_blank">Shop</a>
      </div>
    </nav>
  );
};

export default Navbar;