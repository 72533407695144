import React, { useEffect, useRef } from "react";
import DiceEventList from "./Dice";
import './events.css';
import HamburgerMenu from "../../components/ui/HamburgerMenu";

function EventsApp() {
  const appRef = useRef(null);

  useEffect(() => {
    const moveGradient = (event) => {
      const winWidth = window.innerWidth;
      const winHeight = window.innerHeight;

      const mouseX = Math.round((event.pageX / winWidth) * 100);
      const mouseY = Math.round((event.pageY / winHeight) * 100);

      if (appRef.current) {
        appRef.current.style.setProperty(
          "--mouse-x",
          mouseX.toString() + "%"
        );
        appRef.current.style.setProperty(
          "--mouse-y",
          mouseY.toString() + "%"
        );
      }
    };

    document.addEventListener("mousemove", moveGradient);

    return function cleanup() {
      document.removeEventListener("mousemove", moveGradient);
    };
  }, [appRef]);

  return (
    <div className="eventsContainer" ref={appRef}>
      <HamburgerMenu />
      <h1>Events</h1>
      <div className="eventListContainer">
        <DiceEventList />
      </div>
    </div>
  );
}

export default EventsApp;

