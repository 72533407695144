import React from "react";


const Playlist = () => {
  return (
    <>
    <div className="playlistContainer">
      <p>Discover something new, have a listen to some acts performing at Where Else? soon.</p>
      <div className="spotify-playlist" dangerouslySetInnerHTML={{ __html: `
        <iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/38oVNdQ5DWY2gjLaIJ6PBN?utm_source=generator&theme=0" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
      `}} />
    </div>
  </>
  )
}

export default Playlist; 


















