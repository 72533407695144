import React from "react";
import videoBg2 from '../../assets/4K HQ.mp4'
import logoImg from '../../assets/WhereElse_Logo_3.png'
import Banner from "./Banner";

const Splash = () => {
  return (
    <div className="splashContainer">
      <div className="mainVid">
        <iframe 
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/FDQMzQGVOVM?autoplay=1&mute=1&loop=1&controls=0&playlist=FDQMzQGVOVM" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen 
        />
        <div className="splashLogo">
          <img src={logoImg} alt="Logo" className="logo"/>
        </div>
      </div>
      {/* <Banner/> */}
    </div>
  )
}


export default Splash;



